import apiClient from './../Utils/apiClient';

const getBatches = async () => {
  const bacthes = await apiClient.get('/batches');
  return bacthes;
};

export default {
  getBatches,
};
