import L from 'leaflet';
import * as React from 'react';
import { LayersControl, Map, Marker, TileLayer } from 'react-leaflet';

const satelliteIcon = L.icon({
  iconUrl: './images/sat-icon.png',
  iconSize: [36, 36],
});

/* eslint-disable @typescript-eslint/no-explicit-any */
const whenReady = (event?: { target: any }): void => {
  console.log('Map', event?.target);
}

export default function EarthMap() {
  return (
    <Map
      center={[48.85, 2.44]}
      zoom={3}
      zoomControl={true}
      whenReady={whenReady}
      style={{ width: "100%", height: "100vh" }}
    >
      <LayersControl>
        <LayersControl.BaseLayer name="Base">
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Satellite" checked>
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Other">
          <TileLayer
            url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
            attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
          />
        </LayersControl.BaseLayer>
        <LayersControl.Overlay name="Satellites">
          {/* TODO like https://findstarlink.com/#2988507;3 */}
        </LayersControl.Overlay>
      </LayersControl>
      <Marker position={[48.85, 2.44]} icon={satelliteIcon} />
    </Map>
  );
}
