import * as React from 'react';
import batchApi from './../Api/batch';

type Action = { type: 'get_batches'; batches: [] } | { type: 'decrement' };
type Dispatch = (action: Action) => void;
type State = { batches: [] };
type BatchProviderProps = { children: React.ReactNode };

const BatchStateContext = React.createContext<
  { stateBatch: State; dispatchBatch: Dispatch } | undefined
>(undefined);

function batchReducer(stateBatch: State, action: Action) {
  switch (action.type) {
    case 'get_batches': {
      console.log('get_batches');
      return { batches: action.batches };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function BatchProvider({ children }: BatchProviderProps) {
  const [stateBatch, dispatchBatch] = React.useReducer(batchReducer, {
    batches: [],
  });
  const value = { stateBatch, dispatchBatch };
  return (
    <BatchStateContext.Provider value={value}>
      {children}
    </BatchStateContext.Provider>
  );
}

function useBatch() {
  const context = React.useContext(BatchStateContext);
  if (context === undefined) {
    throw new Error('useBatch must be used within a BatchProvider');
  }
  return context;
}

async function getAllBatches(dispatchBatch: Dispatch) {
  try {
    const batches: [] = await (await batchApi.getBatches()).data;
    dispatchBatch({ type: 'get_batches', batches });
  } catch (error) {
    console.error({ error });
  }
}

export { BatchProvider, useBatch, getAllBatches };
