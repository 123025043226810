import React from 'react';
import { ThemeProvider } from '@mui/material/styles';

import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import theme from './Theme';
import GlobalStyles from './Components/GlobalStyles';
import AllRoutes from './Navigation/Routes';
import apiClient from './Utils/apiClient';
import { BatchProvider } from './Contexts/batch-context';
import monitoringApiClient from './Utils/monitoringApiClient';

function App() {
  /**
   * TODO: To Remove
   * TMP Code
   */
  React.useEffect(() => {
    async function fetchMyAPI() {
      const batches = await apiClient.get('/batches');
      console.log('batches', batches);
      return batches;
    }
    async function fetchSatellitesData() {
      const satellites = await monitoringApiClient.get('/v1/satellites');
      console.log('satellites', satellites);
      return satellites;
    }

    fetchMyAPI();
    fetchSatellitesData();
  });
  // END TMP

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BatchProvider>
          <GlobalStyles />
          <AllRoutes />
        </BatchProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
