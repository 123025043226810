import * as React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import Layout from './../Screens/Layout';
import Home from './../Screens/Home';

function Stats() {
  return (
    <div>
      <h2>Stats</h2>
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default function AllRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="stats" element={<Stats />} />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
}
