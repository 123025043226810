import axios from 'axios';

const access_token = localStorage.getItem('access_token');
const headers: { Authorization?: string } = {};
if (access_token) headers.Authorization = `Bearer ${access_token}`;

export default axios.create({
  baseURL: process.env.REACT_APP_BASE_ENDPOINT || 'https://staging.api.elo.etldna.com',
  headers: headers,
});
