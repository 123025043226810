import axios from 'axios';

const xApiKey = localStorage.getItem('x_api_key');
const headers: { ['x-api-key']?: string } = {};
if (xApiKey) headers['x-api-key'] = xApiKey;

export default axios.create({
  baseURL: process.env.MONITORING_API_ENDPOINT || 'https://staging.monitoring.elo.etldna.com',
  headers: headers,
});
